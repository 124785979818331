
import { defineComponent, ref, onMounted } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Entitytable from "@/components/EntityTable.vue";
import { useRoute, useRouter } from "vue-router";
import PayoutDisplay from "@/components/PayoutDisplay.vue";
import AddPayoutException from "@/components/AddPayoutException.vue";
import { now } from "moment";
import AttachmentList from "@/components/AttachmentList.vue";
import LlAttachmentDisplay from "@/components/AttachmentLastDisplay.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";


export default defineComponent({
  name: "ll-compensation-view",
  components: {
    LlAttachmentDisplay,
    AddPayoutException,
    PayoutDisplay,
    AttachmentList,
  },
  methods: {
    processRequestAccess() {
        ApiService.post(
          "offer/" + this.route.params.id.toString() + "/request",
          {}
        )
        .then((result) => {
          this.refreshData();
        })
        .catch(this.catchErrors);
    },
    catchErrors(error) {
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    refreshData() {
      this.loading = true;
      ApiService.get("offer", this.route.params.id.toString())
        .then((result) => {
          this.offer = result.data.data;
          this.loading = false;
          this.updateKey = now().toString();
        })
        .catch(() => {
          useRouter().push("/offers");
        });
    },
    async getPublishers() {
      return await ApiService.get("publishers").then();
    },
  },
  data() {
    return {
      updateKey: "test",
      offer: {
        vertical_descriptor: {},
        attachments: [],
        name: "",
        country: {
          name: "",
          flag: "",
        },
        vertical: {
          name: "",
        },
      },
      columnData: [
        {
          label: "Offer",
          name: "name",
        },
        {
          label: "Vertical",
          name: "vertical",
        },
        {
          label: "Country",
          name: "country",
        },
        {
          label: "Description",
          name: "description",
        },
        {
          label: "Flow",
          name: "flow",
        },
        {
          label: "Access",
          name: "access",
        },
      ],
    };
  },
  mounted() {
    ApiService.get("affiliates")
      .then((result) => {
        this.affiliateList = result.data.data;
      })
      .then();

    this.refreshData();
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    let affiliateList = ref();
    let loading = ref(false);

    onMounted(() => {
      setCurrentPageTitle("Offer Details");
    });

    return { store, route, affiliateList, loading };
  },
});
